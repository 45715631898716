import React, {useState, useEffect } from "react";
import Loader from "../components/loader"
import { useParams } from "react-router-dom";
import SocketService from "../services/socket.service";
import RaceLiveService from "../services/race-live.service";
import { RaceLive } from "../components/weblive/RaceLive";

const moment = require('moment-timezone');

const CET_TZ = 'Europe/Paris';

export default function PrivateRaceSectionalLive() {

  const {source, RxCx} =  useParams();

  const [loading, set_loading] = useState(true);

  useEffect(() => {
    const [,hippodrome, race_number] = RxCx.match(/^([^0-9]*)([0-9]*)$/);
    const race_date = moment().tz(CET_TZ).format('Y-MM-DD');
    Promise.resolve()
      .then(() => RaceLiveService.init(race_date, hippodrome, race_number))
      .then(() => SocketService.init(source))
      .then(() => set_loading(false))
  }, [source, RxCx]);

  return (
    <div className="race_live_page">
      {loading ? <Loader /> : <RaceLive />}
    </div>
  );
}