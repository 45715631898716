import { RaceHandler } from "../classes/race_handler";

const raceHandler = new RaceHandler();

let onUpdateLive = null;
let onUpdateNext = null;
let previousInit = Promise.resolve();
let data = {};

async function init(race_date, hippodrome, race_number){
    const previous = previousInit;
    previousInit = new Promise((resolve) => 
        previous
            .then(() => data.race_date = race_date)
            .then(() => data.hippodrome = hippodrome)
            .then(() => data.race_number = race_number)
            .then(() => raceHandler.init(race_date, hippodrome, Number(race_number)))
            .then(() => onUpdateLive ? onUpdateLive({reinit: true}) : null )
            .then(() => onUpdateLive ? onUpdateLive(raceHandler.getCurrentRace()) : null )
            .then(() => onUpdateLive ? onUpdateLive({details: raceHandler.getHorsesInfos()}) : null)
            .then(() => onUpdateNext ? onUpdateNext(raceHandler.getNextRaces()) : null)
            .then(() => resolve())
            .catch(error => {
                console.log('Error on initializing live', error);
                resolve();
            })
    );
}

async function reinit() {
    previousInit.then(() => {
        if (data.race_date) {
            return init(data.race_date, data.hippodrome, data.race_number);
        } else {
            console.log('Unable to reinit (no data found)')
        }
    });
}

function callBackOnUpdate(onUpdate) {
    onUpdateLive = onUpdate
    reinit();
}

function callBackOnNextUpdate(onUpdate) {
    onUpdateNext = onUpdate
}

async function updateTo(trame) {
    const hippodrome = Number(trame['Ctxt']['hip']);
    const numc = Number(trame['Ctxt']['cou']);
    const date = trame['Ctxt']['date'];
    const updates = {
        timestamp: trame.Ts,
    };

    // il faut attendre la fin de l'initilisation ... 
    return previousInit.then(() => {
        if (raceHandler.isDateOfRace(date, numc, hippodrome)) {
            const racers = trame.captures.filter(capture => capture.Cpar === 'DP' || capture.Cpar === undefined); // Avant course, les partants sont undefined, pendantkla cours ils sont NP.
            const oneStarted = racers.find(capture => capture.Dep === true || capture.Dcle === 'PCDEP');
            const oneNotEnded = racers.find(capture => capture.Dep === true && capture.Ariv === false)
            updates.race_start = !!oneStarted;
            updates.race_end = !!oneStarted && !oneNotEnded;
            const captures = raceHandler.getHorsesInfosCompleted(trame.captures)
            updates.captures = captures;
            const doors = captures.filter(capture => capture.Dcle !== undefined);
            if (doors.length > 0) {
                updates.doors = doors;
            }
            const sectionals = calculateSectionals(date, hippodrome, numc, trame.captures);
            if (sectionals) {
                const lastSectional = Object
                    .keys(sectionals)
                    .map(door => sectionals[door])
                    .sort((s1, s2) => s1.indexDcle < s2.indexDcle ? 1 : -1)
                    .shift()
                updates.sectional = {...lastSectional};
                updates.sectionals = {...sectionals}; // Pour que _ça prenne en compte le changement dans le useEffect
            }
            updates.speeds = calculateSpeeds(date, hippodrome, numc, trame.captures);
            if (onUpdateLive) {
                return Promise.resolve()
                    .then(() => onUpdateLive(updates))
                    // .then(() => count--)
            } else {
                // count--;
            }
        }
    });
}

// ------------------------------------------------------------------
const SECTIONALS = {};
function calculateSectionals(date, hippodrome, numc, captures) {
    const racekey = `${date}${hippodrome}${numc}`;
    DOORS_NAMES[racekey] = DOORS_NAMES[racekey] || [];
    SECTIONALS[racekey] = SECTIONALS[racekey] || {};
    let isSectionalUpdated = false;
    captures.forEach(capture => {
        const door = capture.Dcle;
        if (door) {
            const doors = calculateRaceDoors(racekey, door);
            const previous = calculatePrevious(racekey, doors, capture.NumP);
            const pRank = previous ? previous.PosC : 0;
            const dRank = pRank === 0 ? 0 : pRank - capture.PosC;
            const stcle = previous ? capture.Tcle - previous.Tcle : capture.Tcle;
            const distSect = previous ? capture.DpDp - previous.DpDp : capture.DpDp;
            const vmoy = 1000 * distSect / stcle;
            const sectional = {
                NumP: capture.NumP,
                PosC: capture.PosC,
                Tcle: capture.Tcle,
                DpDp: capture.DpDp,
                DRank: dRank,
                VmoySection: vmoy,
                STcle: stcle,
            }
            SECTIONALS[racekey][door] = SECTIONALS[racekey][door] || {};
            SECTIONALS[racekey][door].nameDcle = doors.actual;
            SECTIONALS[racekey][door].previousDcle = doors.previous;
            SECTIONALS[racekey][door].indexDcle = doors.index;
            SECTIONALS[racekey][door][capture.NumP] = sectional;
            isSectionalUpdated = true;
        }
    });
    if (isSectionalUpdated) {
        return SECTIONALS[racekey];
    }
    return null;
}

const SMOOTHS = [2,4,10,30,60,120]

const SPEEDS = {};
function calculateSpeeds(date, hippodrome, numc, captures) {
    const racekey = `${date}${hippodrome}${numc}`;
    SPEEDS[racekey] = SPEEDS[racekey] || {};
    captures.forEach(capture => {
        SPEEDS[racekey][capture.NumP] = SPEEDS[racekey][capture.NumP] || [];

        const speed = {
            instant: capture.Vit,
            smooth_global: capture.DpDp && capture.TDLD 
                ? 1000 * capture.DpDp / capture.TDLD // calcul de la vitesse moyenne sur la distance théorique à la corde (100 * car tdld est en ms)
                : 0,
        }

        SMOOTHS.map(SMOOTH => {
            const previous = SPEEDS[racekey][capture.NumP]
                .filter(item => item.instant > 0)
                .filter((item, index, all) => (index + (SMOOTH * 10)) > all.length);
            const nbValue = previous.length + 1;
            const sum = previous.reduce((sum,item) => sum + item.instant, 0) + capture.Vit;
            speed[`smooth_${SMOOTH}`] = sum / nbValue;
        })

        SPEEDS[racekey][capture.NumP].push(speed);
    })
    SPEEDS[racekey] = {
        ...SPEEDS[racekey],
    }
    return SPEEDS[racekey];
}

const DOORS_NAMES = {};
function calculateRaceDoors(racekey, actual) {
    if(!DOORS_NAMES[racekey].includes(actual)){
        DOORS_NAMES[racekey].push(actual);
    }
    const index = DOORS_NAMES[racekey].indexOf(actual);
    const previous = index > 0 ? DOORS_NAMES[racekey][index-1] : null;
    return {
        actual,
        previous,
        index,
    }
}
function calculatePrevious(racekey, doors, NumP) {
    if (!SECTIONALS[racekey]) {
        return null;
    }
    if (!doors.previous) {
        return null;
    }
    if (!SECTIONALS[racekey][doors.previous]) {
        return null;
    }
    if (!SECTIONALS[racekey][doors.previous][NumP]) {
        return null;
    }
    return SECTIONALS[racekey][doors.previous][NumP];
}

function getCurrentDistance() {
    return raceHandler.getCurrentDistance();
}

function getNextRaces() {
    return raceHandler.getNextRaces();
}

const RaceLiveService = {
    init,
    callBackOnUpdate,
    callBackOnNextUpdate,
    updateTo,
    getCurrentDistance,
    getNextRaces,
};
  
export default RaceLiveService;