import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import RaceLiveService from "../../services/race-live.service";

function toHumanSource(code) {
    if (code === 'FG') {
        return 'France Galop';
    }
    if (code === 'LT') {
        return 'Le Trot';
    }
    if (code === 'HB') {
        return 'Hollywood Bet';
    }
    if (code === 'HKJC') {
        return 'Hong Kong Jockey Club';
    }
    if (code === 'GTH') {
        return 'GTH';
    }
    if (code === 'JRA') {
        return 'Japan Racing Association';
    }
    return null;
}

function RaceChoose({race}) {
    // TODO GBE : RxCx : vue qu'on a fait en sorte de plus avoir de RxCx mais un VENx, est ce que la source est encore necessaire ?
    const text = toHumanSource(race.source);
    return (
        <Link to={`/racelive/${race.source}/${race.rxcx}`} className="value">
            <div className="code">{race.rxcx}</div>
            { text ? <div className="source">{text}</div> : null }
        </Link>
    )
}

function RaceChooser({list}) {
    return (
        <>
            <span className="title">Next races:</span>
            <div className="values">
                {list.map(race => <RaceChoose race={race} key={race.key}/>)}
            </div>
        </>
    )
}

export function NextRace() {
    const [list, setList] = useState([]);

    function setNextList(lists) {
        const list = lists.now.length > 0 ? lists.now
                    : lists.near.length > 0 ? lists.near
                    : lists.later.length > 0 ? lists.later
                    : [] ;
        const formated = list.map(race => ({
            ...race,
            key: `${race.source}${race.venueCode}${race.numc}`,
            rxcx: `${race.venueCode}${race.numc}`,
        }))
        setList(formated);
    }

    useEffect(() => {
        setNextList(RaceLiveService.getNextRaces());
        return () => {
            // componentwillunmount in functional component.
            // Anything in here is fired on component unmount.
            RaceLiveService.callBackOnNextUpdate(null)
        }
    }, [])

    RaceLiveService.callBackOnNextUpdate(lists => {
        setNextList(lists)
    })

    return (
        <div className="race_chooser">
            <div className="race_chooser_wrapper">
                { list.length > 0 ? <RaceChooser list={list} /> : 'No more race today'}
            </div>
        </div>
    )
}
